import { useState,useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import LeagueLoadingSpinner from 'components/LeagueLoadingSpinner';
import {boxscoreTeams} from 'api/LeagueAPI';
import { WeekStarts,WeekGameIds,PointEval } from 'api/pll/Utils';
import { PLAYER_EVENT_STATS } from 'api/pll/Player';
import Loader from 'react-loader-spinner';

const weeks_info = {0:'Matchup 1 (Jun 4 - 6)',1:'Matchup 2 (Jun 11 - 13)', 2:'Matchup 3 (Jun 25 - 27)',3:'Matchup 4 (Jul 2 - 4)',4:'Matchup 5 (Jul 9 - 11)',5:'Matchup 6 (Jul 30 - Aug 1)',6:'Matchup 7 (Aug 13 - 15)'}

const TeamCard = props => {
    const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);
    return (
        <div style={{display:'flex',justifyContent:'space-evenly',flexWrap:'no-wrap',overflow:'scroll',backgroundColor:'#FAFAFA',padding:'15px',paddingTop:'25px',borderRadius:'15px'}}>
            <div>
                {props.team_1 ? 
                    <div style={{display:'flex',marginBottom:'10px'}} onClick={() => window.location.href = `/teams/${props.team_1._id}`}>
                        <img src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.team_1.logo}`} style={{height:'66px',borderRadius:'33px'}}/>
                        <div style={{display:'flex',flexDirection:'column',marginLeft:'10px'}}>
                            <h1 style={{margin:'0px',fontSize:'20px',whiteSpace:'nowrap'}}>{props.team_1.name}</h1>
                            <p style={{margin:'0px',fontWeight:'400',whiteSpace:'nowrap'}}>{props.team_1.wins} - {props.team_1.losses}</p>
                            <p style={{margin:'0px',fontWeight:'400',whiteSpace:'nowrap',color:'rgb(146, 146, 146)'}}>{props.team_1.user.first_name} {props.team_1.user.last_name}</p>
                        </div>
                        <div style={{fontWeight:'600',fontSize:'30px',alignSelf:'center',marginLeft:'20px',whiteSpace:'nowrap'}}>
                            {props.team_1.weekly_scores[props.week] ? sumValues(props.team_1.weekly_scores[props.week]) : 0} <span style={{fontWeight:'400'}}>pts</span>
                        </div>
                    </div>
                : <h1 style={{margin:'0px',paddingTop:'10px'}}>Bye</h1>}
            </div>
            <div>
                {props.team_2 ? 
                    <div style={{display:'flex'}} onClick={() => window.location.href = `/teams/${props.team_2._id}`}>
                    <div style={{fontWeight:'600',fontSize:'30px',alignSelf:'center',marginRight:'20px',whiteSpace:'nowrap'}}>
                            {props.team_2.weekly_scores[props.week] ? sumValues(props.team_2.weekly_scores[props.week]) : 0} <span style={{fontWeight:'400'}}>pts</span>
                        </div>
                        <img src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.team_2.logo}`} style={{height:'66px',borderRadius:'33px'}}/>
                        <div style={{display:'flex',flexDirection:'column',marginLeft:'10px'}}>
                            <h1 style={{margin:'0px',fontSize:'20px',whiteSpace:'nowrap'}}>{props.team_2.name}</h1>
                            <p style={{margin:'0px',fontWeight:'400',whiteSpace:'nowrap'}}>{props.team_2.wins} - {props.team_2.losses}</p>
                            <p style={{margin:'0px',fontWeight:'400',whiteSpace:'nowrap',color:'rgb(146, 146, 146)'}}>{props.team_2.user.first_name} {props.team_2.user.last_name}</p>

                        </div>
                    </div>
                : <h1 style={{margin:'0px',paddingTop:'10px'}}>Bye</h1>}
            </div>
        </div>
    );
}

const mergeStats = data => {
  const result = {}; //(1)

  data.forEach(stat_line => { //(2)
    for (let [key, value] of Object.entries(stat_line)) { //(3)
      if (result[key]) { //(4)
        result[key] += value; //(5)
      } else { //(6)
        result[key] = value;
      }
    }
  });
  return result; //(7)
};

const PlayerStats = props => {
    const valid_games = props.player.allEvents.filter(function (event) {
        return WeekGameIds[props.week].includes(event.slugname)
    });
    const valid_stat_lines = valid_games.map(event => event.playerEventStats)
    const mergedStatLines = mergeStats(valid_stat_lines)
    // if (valid_games.length !== 0) {
    //     mergedStatLines.savePct = mergedStatLines.savePct / valid_games.length;
    //     mergedStatLines.faceoffPct = mergedStatLines.savePct / valid_games.length;
    // }
    return (
        <>
            <td style={{textAlign:'center',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px'}}>{mergedStatLines.goals ? mergedStatLines.goals : 0}</td>
            <td style={{textAlign:'center',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px'}}>{mergedStatLines.twoPointGoals ? mergedStatLines.twoPointGoals : 0}</td>
            <td style={{textAlign:'center',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px'}}>{mergedStatLines.assists ? mergedStatLines.assists : 0}</td>
            <td style={{textAlign:'center',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px'}}>{mergedStatLines.groundBalls ? mergedStatLines.groundBalls : 0}</td>
            <td style={{textAlign:'center',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px'}}>{mergedStatLines.turnovers ? mergedStatLines.turnovers : 0}</td>
            <td style={{textAlign:'center',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px'}}>{mergedStatLines.causedTurnovers ? mergedStatLines.causedTurnovers : 0}</td>
            <td style={{textAlign:'center',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px'}}>{mergedStatLines.savePct ? ((valid_stat_lines.reduce(function(prev,cur) {return prev + cur.savePct },0) / valid_stat_lines.length)*100).toFixed(0)+'%' : 'N/A'}</td>
            <td style={{textAlign:'center',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px'}}>{mergedStatLines.faceoffPct ? ((valid_stat_lines.reduce(function(prev,cur) {return prev + cur.faceoffPct },0) / valid_stat_lines.length)*100).toFixed(0)+'%' : 'N/A'}</td>
            <td style={{textAlign:'center',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px',fontWeight:'500'}}>{PointEval(mergedStatLines,props.player.currentTeam.position)}</td>
        </>
    );
}

const PlayerRow = props => {
    const { loading, error, data } = useQuery(PLAYER_EVENT_STATS, {
        variables : {id: props.id},
        pollInterval: 3000
    });
    return (
        (loading ? <Loader
         type="Oval"
         color="#99ccff"
         height={25}
         width={25}
         timeout={5000} /> :
        <tr >
            <td style={{display:'flex',alignItems:'center',fontWeight:'500',fontSize:'19px',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px'}}><img src={data.player.profileUrl} style={{height:'30px',borderRadius:'10px'}} /><span style={{marginLeft:'10px'}}>{data.player.firstName} {data.player.lastName}</span></td>
            <td style={{textAlign:'center',borderBottom:'1px solid rgba(224, 224, 224, 1)',padding:'6px'}}>{data.player.currentTeam.position}</td>
            <PlayerStats week={props.week} player={data.player} />
        </tr>
        )
    );
}

const TeamPlayers = props => {
    return (
        <div style={{overflow:'scroll',width:'100%',marginBottom:'30px'}}>
        <table style={{width:'100%',borderCollapse:'collapse'}}>
            <thead>
                <tr>
                    <th style={{whiteSpace:'nowrap',minWidth:'200px',textAlign:'left',fontWeight:'400'}}>Player</th>
                    <th style={{whiteSpace:'nowrap',fontWeight:'400'}}>POS</th>
                    <th style={{whiteSpace:'nowrap',fontWeight:'400'}}>G</th>
                    <th style={{whiteSpace:'nowrap',fontWeight:'400'}}>2PT G</th>
                    <th style={{whiteSpace:'nowrap',fontWeight:'400'}}>A</th>
                    <th style={{whiteSpace:'nowrap',fontWeight:'400'}}>GB</th>
                    <th style={{whiteSpace:'nowrap',fontWeight:'400'}}>T</th>
                    <th style={{whiteSpace:'nowrap',fontWeight:'400'}}>CT</th>
                    <th style={{whiteSpace:'nowrap',fontWeight:'400'}}>Save %</th>
                    <th style={{whiteSpace:'nowrap',fontWeight:'400'}}>Faceoff %</th>
                    <th style={{whiteSpace:'nowrap',fontWeight:'400'}}>PTS</th>
                </tr>
            </thead>
            <tbody style={{overflow:'scroll'}}>
                {props.players.map((player) => (
                    player ? <PlayerRow week={props.week} id={player} /> : null
                ))}
            </tbody>
        </table>
        </div>
    );
}

const TeamBoxScores = props => {
    if (WeekStarts[props.week] > new Date()) {
        return (
            <center><h2>There is no available game data.</h2></center>
        )
    }
    return (
        <div style={{marginTop:'30px',backgroundColor:'#FAFAFA',padding:'10px',borderRadius:'15px'}}>
            {props.data.matchup.team_1 ?
                <>
                <div style={{display:'flex',alignItems:'center',marginBottom:'20px'}}>
                    <img src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.data.matchup.team_1.logo}`} style={{height:'40px',borderRadius:'20px'}}/>
                    <h2 style={{margin:'0px',marginLeft:'10px'}}>{props.data.matchup.team_1.name} <span style={{fontWeight:'400'}}>Box Score</span></h2>
                </div>
                <TeamPlayers week={props.week} players={props.data.matchup.team_1.lineup} />
                </>
            : null}
            {props.data.matchup.team_2 ? 
                <>
                <div style={{display:'flex',alignItems:'center',marginBottom:'20px'}}>
                    <img src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.data.matchup.team_2.logo}`} style={{height:'40px',borderRadius:'20px'}}/>
                    <h2 style={{margin:'0px',marginLeft:'10px'}}>{props.data.matchup.team_2.name} <span style={{fontWeight:'400'}}>Box Score</span></h2>
                </div>
                <TeamPlayers week={props.week} players={props.data.matchup.team_2.lineup} />
                </>
            : null}
        </div>
    );
}

function BoxScore({match,location}) {
    const { params : { id } } = match;
    const urlParams = new URLSearchParams(window.location.search);
    const [week,setWeek] = useState(urlParams.get('week'))
    const matchupId = urlParams.get('matchupId')
    const [isLoading,setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    useEffect(() => {
        setIsLoading(true);
        boxscoreTeams(id,week,matchupId).then(data => {
            setData(data);
        });
        setIsLoading(false);
    }, []);
    useEffect(() => {
        if (data) {
            setWeek(data.week)
        }
    }, [data]);
    return (
        <>
            <Nav league={true}/>
            <LeagueNav id={id}/>
            <div className="league-main">
                <h1>{week === 'current' ? 'Current' : weeks_info[week]} Box Scores <span style={{fontSize:'20px',fontWeight:'500',color:'#4A4A4A',whiteSpace:'nowrap'}}>{data ? data.name : ''}</span></h1>
                {isLoading | !data ? <LeagueLoadingSpinner /> :
                <>
                    <TeamCard week={week} team_1={data.matchup.team_1} team_2={data.matchup.team_2} />
                    <TeamBoxScores week={week} data={data} />
                </>
                }
            </div>
        </>
    );
}

export default BoxScore;