import Cookies from 'js-cookie';

export const getLeague = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    };
    const data = await fetch(`/api/leagues/${id}`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const saveLeagueNote = async (id,data) => {
    let new_note = new FormData();
    new_note.append('note',data['note'])
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        body: new_note
    };
    const resp = await fetch(`/api/leagues/${id}`,requestOptions).then(res =>
        res.json()
    )
    return resp
}

export const leagueStateListener = async (id) => {
    var previousState;
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
    };
    const resp = await fetch(`/api/leagues/${id}/state`,requestOptions).then(res =>
        res.json()
    )
    if (resp['state'] !== previousState && previousState !== undefined) {
        window.location.href = `/league/${id}`
    } else {
        var previousState = resp['state'];
    }
    return resp
}

export const getLeagueMangers = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    };
    const data = await fetch(`/api/leagues/${id}/managers`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const startDraft = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    };
    const data = await fetch(`/api/leagues/${id}/startDraft`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const getTeam = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    };
    const data = await fetch(`/api/teams/${id}/me`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const setLineup = async (id,lineup) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`,'content-type': 'application/json'},
        body: JSON.stringify(lineup)
    };
    const data = await fetch(`/api/teams/${id}/me`,requestOptions).then(res =>
        res.json()
        
    )
    if (data.hasOwnProperty('error')) {
        return false
    }
    return data
}

export const dropPlayer = async (id,player_id) => {
    let drop = new FormData();
    drop.append('player',player_id)
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        body: drop
    };
    const resp = await fetch(`/api/teams/${id}/me`,requestOptions).then(res =>
        res.json()
    )
    return resp
}

export const getWaiver = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    };
    const data = await fetch(`/api/leagues/${id}/waiver`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const pickupPlayer = async (id,player_id) => {
    let pickup = new FormData();
    pickup.append('player',player_id)
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        method: 'POST',
        body: pickup
    }
    const resp = await fetch(`/api/leagues/${id}/waiver`,requestOptions);
    if (!resp.ok) {
        throw new Error(`An error has occurred: ${resp.status}`)
    }
    const json = await resp.json();
    return json;
}

export const getMatchups = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/leagues/${id}/matchups`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const initProposal = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/leagues/${id}/trades/propose`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const getPublicTeam = async (teamId) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/teams/${teamId}`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const getTrades = async (id,type) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/leagues/${id}/trades?type=${type}`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const getNotiifcations = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/leagues/${id}/notifications`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const getPlayer = async (player_id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/players/${player_id}`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const getTeams = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/leagues/${id}/teams`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const getTeamSettings = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/leagues/${id}/teamSettings`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const deleteTeam = async (team_id) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/delete/${team_id}`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const upcomingMatchup = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/leagues/${id}/upcoming`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const leagueStandings = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/leagues/${id}/standings`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const scoreboard = async (id, week) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    const data = await fetch(`/api/leagues/${id}/scoreboard?week=${week}`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const boxscoreTeams = async (id, week, matchupId) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    }
    // Returns both teams rosters - two lists of player ids
    const res = await fetch(`/api/leagues/${id}/boxscore?week=${week}&matchupId=${matchupId}`,requestOptions)
    const data = await res.json()
    return data
}