import {
  BrowserRouter as Router,
  Route
  } from "react-router-dom";
import { AuthenticatedRoute } from 'helpers/Auth';
import Login from 'pages/Login';
import Leagues from 'pages/Leagues';
import SignUp from 'pages/SignUp';
import ResetInit from "pages/ResetInit";
import Reset from 'pages/Reset';
import CreateLeague from 'pages/CreateLeague';
import League from 'pages/League';
import Join from 'pages/Join';
import Prism from 'pages/Prism';
import Settings from 'pages/Settings';
import TeamSettings from 'pages/league/TeamSettings';
import Share from 'pages/Share';
import Home from 'pages/Home';
import Daily from 'pages/daily/Daily';
import CreateContest from 'components/daily/Create';
import DailyLineups from 'components/daily/DailyLineups';
import DailyContests from 'components/daily/DailyContests';
import DailyLeaderboard from 'components/daily/DailyLeaderboard';
import DailyLineupView from 'components/daily/DailyLineupView';
import ResetPost from 'pages/ResetPost';
import DailyPlayers from 'components/daily/DailyPlayers';
import TermsOfService from 'pages/TermsOfService';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import CreateLineup from 'components/daily/CreateLineup';
import Managers from 'pages/league/Managers';
import Team from 'pages/league/Team';
import Waiver from 'pages/league/Waiver';
import Matchups from 'pages/league/Matchups';
import Chat from 'pages/league/Chat';
import Trades from 'pages/league/Trades';
import ProposeTrade from 'pages/league/ProposeTrade';
import Notifications from 'pages/league/Notifications';
import Player from 'pages/league/Player';
import Teams from 'pages/league/Teams';
import LeagueTeam from 'pages/league/LeagueTeam';
import Standings from 'pages/league/Standings';
import Scoreboard from 'pages/league/Scoreboard';
import BoxScore from 'pages/league/BoxScore';
import { QueryClient, QueryClientProvider } from 'react-query';
 
 const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <Router>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/forgotPassword" component={ResetInit} />
        <Route exact path="/resetPassword" component={ResetPost} />
        <Route path="/reset/:resetToken" component={Reset} />
        <Route exact path="/tos" component={TermsOfService} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <AuthenticatedRoute path="/leagues" component={Leagues} />
        <AuthenticatedRoute path="/share" component={Share} />
        <AuthenticatedRoute path="/createLeague" component={CreateLeague} />
        <AuthenticatedRoute path="/player/:playerid" component={Player}/>
        <AuthenticatedRoute exact path="/league/:id" component={League}/>
        <AuthenticatedRoute exact path="/league/:id/team" component={Team} /> 
        <AuthenticatedRoute exact path="/league/:id/managers" component={Managers} />
        <AuthenticatedRoute exact path="/league/:id/waiver" component={Waiver} />
        <AuthenticatedRoute exact path="/league/:id/matchups" component={Matchups} />
        <AuthenticatedRoute exact path="/league/:id/chat" component={Chat} />
        <AuthenticatedRoute exact path="/league/:id/trades" component={Trades} />
        <AuthenticatedRoute exact path="/league/:id/trades/propose" component={ProposeTrade} />
        <AuthenticatedRoute exact path="/league/:id/notifications" component={Notifications} />
        <AuthenticatedRoute exact path="/league/:id/teamSettings" component={TeamSettings} />
        <AuthenticatedRoute exact path="/league/:id/teams" component={Teams} />
        <AuthenticatedRoute exact path="/league/:id/standings" component={Standings} />
        <AuthenticatedRoute exact path="/league/:id/scoreboard" component={Scoreboard} />
        <AuthenticatedRoute exact path="/league/:id/boxscore" component={BoxScore} />
        <AuthenticatedRoute exact path="/teams/:team_id" component={LeagueTeam} />
        <AuthenticatedRoute path="/join/:id" component={Join} />
        <AuthenticatedRoute path="/prism" component={Prism} />
        <AuthenticatedRoute path="/settings" component={Settings} />
        <AuthenticatedRoute exact path="/daily/lineups" component={DailyLineups} />
        <AuthenticatedRoute exact path="/daily" component={Daily} />
        <AuthenticatedRoute exact path="/daily/contests" component={DailyContests} />
        <AuthenticatedRoute exact path="/daily/create" component={CreateContest} />
        <AuthenticatedRoute exact path="/daily/leaderboard" component={DailyLeaderboard}/>
        <AuthenticatedRoute path="/daily/lineups/:lineup_id" component={DailyLineupView}/>
        <AuthenticatedRoute path="/daily/players" component={DailyPlayers}/>
        <AuthenticatedRoute exact path="/daily/newLineup" component={CreateLineup} />
    </Router>
    </QueryClientProvider>
  );
}

export default App;
