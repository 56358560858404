const GOAL = 5;
const ASSIST = 4;
const TWO_POINT_GOAL = 6;
const GB = 1;
const TURNOVER = -2;
const CAUSED_TURNOVER = 4;

export const WeekGameIds = {
    0:['cannons-redwoods-2021-6-04','whipsnakes-chaos-2021-6-05','archers-atlas-2021-6-05','waterdogs-cannons-2021-6-06','chrome-redwoods-2021-6-06'],
    1:['chaos-waterdogs-2021-6-11','whipsnakes-cannons-2021-6-12','redwoods-atlas-2021-6-12','chrome-waterdogs-2021-6-13','chaos-archers-2021-6-13'],
    2:['whipsnakes-atlas-2021_6_25','redwoods-chaos-2021-6-26','waterdogs-archers-2021-6-26','cannons-atlas-2021-6-27','chrome-whipsnakes-2021-6-27'],
    3:['archers-chrome-2021-7-2','redwoods-waterdogs-2021-7-3','cannons-chaos-2021-7-3','chrome-atlas-2021-07-04','whipsnakes-archers-2021-7-4'],
    4:['waterdogs-cannons-2021-7-9','redwoods-whipsnakes-2021-7-10','atlas-chaos-2021-7-10','chrome-waterdogs-2021-7-11','cannons-archers-2021-7-11'],
    5:['atlas-chrome-2021-7-30','archers-redwoods-2021-7-31','waterdogs-whipsnakes-2021-7-31','chaos-chrome-2021-8-1','atlas-cannons-2021-8-1'],
    6:['redwoods-whipsnakes-2021-8-13','archers-chaos-2021-08-13','atlas-waterdogs-2021-8-14','cannons-chrome-2021-8-14','chaos-redwoods-2021-8-15','archers-whipsnakes-2021-8-15']
}

export const WeekStarts = {0: new Date(1622847600 * 1000), 1: new Date(1623452400 * 1000), 2: new Date(1624665600 * 1000), 3: new Date(1625266800 * 1000), 4: new Date(1625875200 * 1000), 5: new Date(1627693200  * 1000), 6: new Date(1628892000 * 1000)}

const resolveFaceoff = (stat_line) => {
    var total = 0;
    if (stat_line['faceoffPct'] != 0.0) {
        if (stat_line['faceoffPct'] < .20) {
            total -= 12;
        } else if (.20 < stat_line['faceoffPct'] < .25) {
            total -= 8;
        } else if (.25 < stat_line['faceoffPct'] < .30) {
            total -= 6;
        } else if (.30 < stat_line['faceoffPct'] < .35) {
            total -= 4;
        } else if (.35 < stat_line['faceoffPct'] < .40) {
            total -= 3;
        } else if (.40 < stat_line['faceoffPct'] < .45) {
            total -= 2;
        } else if (.45 < stat_line['faceoffPct'] < .50) {
            total -= 1;
        } else if (.50 < stat_line['faceoffPct'] < .55) {
            total += 3;
        } else if (.55 < stat_line['faceoffPct'] < .60) {
            total += 5;
        } else if (.60 < stat_line['faceoffPct'] < .65) {
            total += 7;
        } else if (.65 < stat_line['faceoffPct'] < .70) {
            total += 9;
        } else if (.70 < stat_line['faceoffPct'] < .75) {
            total += 11
        } else if (.75 < stat_line['faceoffPct'] < .80) {
            total += 13
        } else if (stat_line['faceoffPct'] > .80) {
            total += 17
        }
    }
    return total
}

const resolveGoalie = (stat_line) => {
    var total = 0;
    if (stat_line['savePct'] != 0.0) {
        if (stat_line['savePct'] < .20) {
            total -= 12;
        } else if (.20 < stat_line['savePct'] < .25) {
            total -= 8;
        } else if (.25 < stat_line['savePct'] < .30) {
            total -= 6;
        } else if (.30 < stat_line['savePct'] < .35) {
            total -= 4;
        } else if (.35 < stat_line['savePct'] < .40) {
            total -= 3;
        } else if (.40 < stat_line['savePct'] < .45) {
            total -= 2;
        } else if (.45 < stat_line['savePct'] < .50) {
            total -= 1;
        } else if (.50 < stat_line['savePct'] < .55) {
            total += 3;
        } else if (.55 < stat_line['savePct'] < .60) {
            total += 5;
        } else if (.60 < stat_line['savePct'] < .65) {
            total += 7;
        } else if (.65 < stat_line['savePct'] < .70) {
            total += 9;
        } else if (.70 < stat_line['savePct'] < .75) {
            total += 11
        } else if (.75 < stat_line['savePct'] < .80) {
            total += 13
        } else if (stat_line['savePct'] > .80) {
            total += 17
        }
    }
    return total
}

export const PointEval = (stat_line,position) => {
    var points = 0;
    if (stat_line['faceoffPct'] !== undefined & position === 'FO') {
        console.log('in')
        points += resolveFaceoff(stat_line)+6
    }
    if (stat_line['savePct'] !== undefined & position == 'G') {
        points += resolveGoalie(stat_line)+6
    }
    if (stat_line['goals'] !== undefined) {
        points += GOAL*stat_line['goals']
    }
    if (stat_line['assists'] !== undefined) {
        points += ASSIST*stat_line['assists']
    }
    if (stat_line['groundBalls'] !== undefined) {
        points += GB*stat_line['groundBalls']
    }
    if (stat_line['turnovers'] !== undefined) {
        points += TURNOVER*stat_line['turnovers']
    }
    if (stat_line['causedTurnovers'] !== undefined) {
        points += CAUSED_TURNOVER*stat_line['causedTurnovers']
    }
    return points
}