import {useState,useEffect} from 'react';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import { Row, Col } from 'react-simple-flex-grid';
import {useQuery,useMutation} from 'react-query';
import {getTeam,setLineup,dropPlayer} from 'api/LeagueAPI';
import 'stylesheets/Team.css';
import LoadingSpinner from 'components/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import { PlayerStats } from 'components/league/PlayerTableStats';
const action_expiry = () => toast("🛑  The games for the week have started. No changes made to your lineup will be saved.");

const type_0 = {'A':2,'M':2,'FLEX':2,'FO':1,'D':2,'G':1,'ALT':0}
const type_1 = {'A':2,'M':2,'ALT':1,'D':2,'G':0,'FLEX':0,'FOGO':0}
const total_positions = {'A':2,'M':2,'FLEX':2,'FO':1,'D':2,'G':1,'ALT':1}

const available_position = {'A':['A','FLEX'],'M':['M','FLEX'],'FO':['FO','ALT'],'D':['D','FLEX'],'G':['G','ALT'],'LSM':['FLEX','M'],'SSDM':['FLEX','D','M']}

const PlayerLineupRow = props => {
    var player = props.position_data[props.position_index];
    if (!player) {
        return (
            <tr >
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}>{props.position}</td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}>{props.moving.includes(props.position) ? <button onClick={() => props.complete_move(props.position,props.position_index)} className="team-add-player">Here</button> : null}</td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}></td>
            </tr>
        ); 
    } else {
        return (
            <tr>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}>{props.position}</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}><span style={{display:'flex',alignItems:'center',marginLeft:'40px',fontWeight:'500',fontSize:'19px'}}><img src={player.profileUrl} className="team-player-img" style={{height:'30px',borderRadius:'10px',marginRight:'10px'}} />{player.firstName} {player.lastName}</span></td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{props.moving.includes(props.position) && props.dropping === false ? <button className="team-add-player" onClick={() => props.complete_switch(props.position,props.position_index)}>Here</button> : (props.dropping === true ? <button className="drop-player" onClick={() => props.drop(player.officialId,props.position,props.position_index)}>Drop</button> : null)}</td>
                <td/>
                <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}>{player.next_game.opponent}</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{format_date(player.next_game.start)}</td>
                <PlayerStats team={player.teamId} id={player.officialId} filter={props.statsFilter} />
            </tr>
        );
    }
}

const PlayerBenchRow = props => {
    const {player} = props;
    const local_move = (player) => {
        if (props.playerBeingMoved.officialId === player.officialId) {
            props.setPlayerBeingMoved({});
            props.setMoving([]);
        } else {
            props.move(player)
        }
    } 
    return (
        <tr style={{maxHeight:'50px'}}>
            <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}>{player.position}</td>
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}} className={`${props.playerBeingMoved.officialId === player.officialId ? 'player-bench-selected' : ''}`}><span style={{display:'flex',alignItems:'center',marginLeft:'40px',fontWeight:'500',fontSize:'19px'}}><img src={player.profileUrl} className="team-player-img" style={{height:'30px',borderRadius:'10px',marginRight:'10px'}} />{player.firstName} {player.lastName}</span></td>
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{props.dropping === false ? <button className="team-add-player" onClick={() => local_move(player)}>Move</button> : <button className="drop-player" onClick={() => props.drop(player.officialId,null,null)}>Drop</button>}</td>
            <td/>
            <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}>{player.next_game.opponent}</td>
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{format_date(player.next_game.start)}</td>
            <PlayerStats team={player.teamId} id={player.officialId} filter={props.statsFilter} />
        </tr>
    );
}

const StatsSort = props => {
    return (
        <select className="team-stats-select" onChange={(e) => props.setStatsFilter(e.target.value)}>
            <option value="2021">2021 Stats</option>
            <option value="2020">2020 Stats</option>
            <option value="2019">2019 Stats</option>
        </select>
    );
}

const format_date = (date_str) => {
    var newDate = new Date(date_str);
    var final_date_str = `${newDate.getMonth()+1}/${newDate.getDate()}`;
    return final_date_str;

}

const PlayerTable = props => {
    const [attack,setAttack] = useState([]);
    const [midfield,setMidfield] = useState([]);
    const [flex,setFlex] = useState([]);
    const [faceoff,setFaceoff] = useState([]);
    const [defense,setDefense] = useState([]);
    const [goalie,setGoalie] = useState([]);
    const [alt,setAlt] = useState([]);
    const [moving,setMoving] = useState([]);
    const [playerBeingMoved,setPlayerBeingMoved] = useState({});
    const [bench,setBench] = useState(props.players);
    useEffect(() => {
        setMoving([]);
        setPlayerBeingMoved({});
    }, [props.dropping]);
    useEffect(() => {
        if (props.lineup) {
        if (props.lineup[0]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[0]
            });
            attack[0] = player[0];
        }
        if (props.lineup[1]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[1]
            });
            attack[1] = player[0];
        }
        if (props.lineup[2]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[2]
            });
            midfield[0] = player[0];
        }
        if (props.lineup[3]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[3]
            });
            midfield[1] = player[0];
        }
        if (props.lineup[4]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[4]
            });
            flex[0] = player[0];
        }
        if (props.lineup[5]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[5]
            });
            flex[1] = player[0];
        }
        if (props.lineup[6]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[6]
            });
            faceoff[0] = player[0];
        }
        if (props.lineup[7]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[7]
            });
            defense[0] = player[0];
        }
        if (props.lineup[8]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[8]
            });
            defense[1] = player[0];
        }
        if (props.lineup[9]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[9]
            });
            goalie[0] = player[0];
        }
        if (props.lineup[10]) {
            var player = bench.filter(obj => {
                return obj.officialId === props.lineup[10]
            });
            alt[0] = player[0];
        }
        setBench(bench.filter(player => !props.lineup.includes(player.officialId)));
        }
    }, []); 
    const state_map = {'A':attack,'M':midfield,'FLEX':flex,'FO':faceoff,'D':defense,'G':goalie,'ALT':alt}
    const mutation = useMutation(lineup => setLineup(props.id,lineup));
    const create_change = async () => {
        console.log('Setting Lineup...')
        var lineup = [];
        for (var key of Object.keys(state_map)) {
            state_map[key].map((player) => {
                if (player) {
                    lineup.push(player.officialId)
                }
            });
            var delta = total_positions[key] - state_map[key].length;
            for (var d = 0; d < delta; d++) {
                lineup.push(null)
            }
        }
        console.log(lineup)
        var resp = await setLineup(props.id,lineup)
        if (resp === false) {
            action_expiry()
        }
    }
    const move = (player) => {
        var positions = available_position[player.position]
        var final_positions = [];
        if (props.type === 0) {
            var league_type = type_0;
        } else {
            var league_type = type_1;
        }
        positions.map((position) => {
            if (league_type[position] !== 0) {
                final_positions.push(position)
            }
        });
        setMoving(final_positions);
        setPlayerBeingMoved(player);
    }
    const complete_move = (position,position_index) => {
        state_map[position][position_index] = playerBeingMoved;
        setBench(bench.filter(player => player.officialId !== playerBeingMoved.officialId));
        setPlayerBeingMoved({});
        setMoving([]);
        create_change()
    }
    const complete_switch = (position,position_index) => {
        bench.push(state_map[position][position_index]);
        state_map[position][position_index] = playerBeingMoved;
        setBench(bench.filter(player => player.officialId !== playerBeingMoved.officialId));
        setPlayerBeingMoved({});
        setMoving([]);
        create_change();
    }
    const drop = (player_id,position,position_index) => {
        if (position !== null) {
            state_map[position][position_index] = null;
        }
        setBench(bench.filter(player => player.officialId !== player_id));
        dropPlayer(props.id,player_id);
    }
    return (
        <div style={{overflow:'scroll',width:'100%'}}>
        <table className="my-team-table" style={{minWidth:'900px'}}>
            <thead >
                <tr >
                    <th colspan="4" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup' >Players</th>
                    <th colspan="2" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup'>Next Game</th>
                    <th colspan="8" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup'>Stats</th>
                    <th colspan="2" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup'>Basics</th>
                </tr>
                <tr>
                    <th className="team-sub-header" scope='col'>SLOT</th>
                    <th className="team-sub-header" style={{minWidth:'200px'}} scope='col'>PLAYER</th>
                    <th className="team-sub-header" scope='col' />
                    <th className="team-sub-header" scope='col'/>
                    <th className="team-sub-header" scope='col'>OPP</th>
                    <th className="team-sub-header" scope='col'>TIME</th>
                    <th className="team-sub-header" scope='col'>G</th>
                    <th className="team-sub-header" scope='col'>2PT G</th>
                    <th className="team-sub-header" scope='col'>A</th>
                    <th className="team-sub-header" scope='col'>GB</th>
                    <th className="team-sub-header" scope='col'>T</th>
                    <th className="team-sub-header"  scope='col'>CT</th>
                    <th className="team-sub-header" scope='col'>Save %</th>
                    <th className="team-sub-header" scope='col'>Faceoff %</th>
                    <th className="team-sub-header" scope='col'>TEAM</th>
                    <th className="team-sub-header" scope='col'>Pts/Game</th>
                </tr>
            </thead>
            <tbody>
                {props.type === 0 ? 
                Object.keys(type_0).map((position) => (
                    (Array(type_0[position]).fill(null).map((value, index) => (
                        <PlayerLineupRow moving={moving} position={position} position_data={state_map[position]} position_index={index} complete_move={complete_move} drop={drop} dropping={props.dropping} statsFilter={props.statsFilter} moving={moving} complete_switch={complete_switch}/>
                    )))
                )) : 
                Object.keys(type_1).map((position,index) => (
                    (Array(type_1[position]).fill(null).map((value, index) => (
                        <PlayerLineupRow moving={moving} position={position} position_data={state_map[position]} position_index={index} complete_move={complete_move} drop={drop} dropping={props.dropping} statsFilter={props.statsFilter} moving={moving} complete_switch={complete_switch}/>
                    )))
                ))
                }
            </tbody>
            <thead style={{backgroundColor:'#E5E5E5',width:'100%'}}>
                <tr style={{backgroundColor:'#E5E5E5',width:'100%'}}>   
                    <th style={{backgroundColor:'#E5E5E5',height:'50px'}}>Bench</th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                    <th style={{backgroundColor:'#E5E5E5'}}></th>
                </tr>
            </thead>
            <tbody style={{borderTop:'5px solid #E5E5E5'}}>
                {bench ? bench.map((player) => (
                    <PlayerBenchRow player={player} statsFilter={props.statsFilter} playerBeingMoved={playerBeingMoved} setPlayerBeingMoved={setPlayerBeingMoved} setMoving={setMoving} move={move} drop={drop} dropping={props.dropping}/>
                )) : null}
            </tbody>
        </table>
        </div>
    );
}

function Team({match,location}) {
    const { params: { id } } = match;
    const { isLoading, error, data } = useQuery('team',() => getTeam(id));
    const [statsFilter,setStatsFilter] = useState('2020');
    const [dropping,setDropping] = useState(false);
    return (
        (isLoading ? <LoadingSpinner/> :
        <>
            <Nav league={true} />
            <LeagueNav current="team" id={id} />
            <div className="league-main" style={{marginRight:'0px',paddingRight:'0px'}}>
                <h2 className="my-team-header-collapse" style={{marginBottom:'5px'}}>My Team</h2>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <h1 className="my-team-header">My Team</h1>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <button className={`team-drop-player ${dropping === true ? 'dropping-players' : ''}`} onClick={() => setDropping(!dropping)}>Drop Players</button>
                        <StatsSort setStatsFilter={setStatsFilter} edge={data.edge} />
                    </div>
                </div>
                <ToastContainer />
                <PlayerTable players={data.players} dropping={dropping} lineup={data.lineup} statsFilter={statsFilter} lineup={data.lineup} type={data.type} id={id}/>
            </div>
            
        </>
        )
    );
}

export default Team;