import { gql } from '@apollo/client';

export const PLAYER_STATS_YEARLY_CUMULATIVE = gql`
    query($id: ID!, $year: Int) {
        player(id: $id, forYear: $year) {
            stats(segment: regular) {
                GAA
                assists
                causedTurnovers
                faceoffPct
                faceoffs
                faceoffsLost
                faceoffsWon
                foRecord
                gamesPlayed
                gamesStarted
                goalieLosses
                goalieTies
                goalieWins        
                goals
                goalsAgainst
                groundBalls
                numPenalties
                onePointGoals
                pim
                plusMinus
                points
                powerPlayGoals
                powerPlayGoalsAgainst
                powerPlayShots
                saa
                savePct
                saves
                scoresAgainst
                shortHandedGoals
                shortHandedGoalsAgainst
                shortHandedShots
                shotPct
                shots
                shotsOnGoal
                shotsOnGoalPct
                tof
                turnovers
                twoPointGoals
                twoPointGoalsAgainst
                twoPointShots  
                twoPointShotsOnGoal  
                twoPointShotsOnGoalPct
                twoPtGaa
            }
        }
    }
`;

export const PLAYER_EVENT_STATS = gql`
    query($id: ID!) {
        player(id:$id, forYear: 2021) {
        officialId
        firstName
        lastName
        profileUrl
        currentTeam {
            officialId
            position
        }
        allEvents {
            slugname
            startTime
            homeTeam {
                officialId
            }
            awayTeam {
                officialId
            }
            playerEventStats {
                gamesPlayed
                goals
                assists
                points
                turnovers
                shots
                shotPct
                shotsOnGoal
                shotsOnGoalPct
                gamesStarted
                onePointGoals
                twoPointGoals
                saves
                savePct
                scoresAgainst
                foRecord
                faceoffPct
                causedTurnovers
                groundBalls
                pimValue
            }
        }
        }
    }
`