import {leagueStandings} from 'api/LeagueAPI';
import {useQuery} from 'react-query';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import LeagueLoadingSpinner from 'components/LeagueLoadingSpinner';

const LeagueStandings = props => {
    var teams = props.league.teams;
    teams.sort((a, b) => (a.wins < b.wins) ? 1 : -1)

    return (
        <>
        <table style={{borderCollapse:'collapse'}}>
            <thead>
                <tr >
                    <th style={{borderBottom:'1px solid #4A4A4A',borderRight:'1px solid #4A4A4A',padding:'6px',fontWeight:'400',paddingRight:'15px',paddingLeft:'15px'}}>RK</th>
                    <th style={{borderBottom:'1px solid #4A4A4A',padding:'6px',fontWeight:'400',paddingLeft:'15px',paddingRight:'70px'}}>Team</th>
                    <th style={{borderBottom:'1px solid #4A4A4A',padding:'6px',fontWeight:'400'}}>W</th>
                    <th style={{borderBottom:'1px solid #4A4A4A',padding:'6px',fontWeight:'400'}}>L</th>
                    <th style={{borderBottom:'1px solid #4A4A4A',padding:'6px',fontWeight:'400'}}>PCT</th>
                </tr>
            </thead>
            <tbody>
            {teams.map((team,idx) => (
                <tr style={{fontWeight:`${team.yours === true ? '600' : '400'}`}}>
                    <td style={{textAlign:'center',padding:'0.5em',borderBottom:'1px solid #4A4A4A'}}>{idx+1}</td>
                    <td style={{paddingLeft:'15px',borderLeft:'1px solid #4A4A4A',padding:'0.5em',borderBottom:'1px solid #4A4A4A'}}><a href={`/teams/${team._id}`} style={{display:'flex',alignItems:'center',color:'#000'}}><img src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${team.logo}`} style={{height:'30px',borderRadius:'15px',marginRight:'10px'}} /> {team.name}</a></td>
                    <td style={{textAlign:'center',padding:'0.5em',borderBottom:'1px solid #4A4A4A'}}>{team.wins}</td>
                    <td style={{textAlign:'center',padding:'0.5em',borderBottom:'1px solid #4A4A4A'}}>{team.losses}</td>
                    <td style={{textAlign:'center',padding:'0.5em',borderBottom:'1px solid #4A4A4A'}}>{team.losses != 0 ? ((team.wins/(team.losses+team.wins))*100).toFixed(0) : 100}%</td>
                </tr>
            ))}
            </tbody>
        </table>
        
        </>
    );
}

function Standings({match,location}) {
    const { params: { id } } = match;
    const { isLoading, error, data } = useQuery('standings',() => leagueStandings(id));
    return (
        <>
            <Nav league={true}/>
            <LeagueNav id={id}/>
            <div className="league-main">
                <h1>Standings <span style={{fontSize:'20px',fontWeight:'500',color:'#4A4A4A'}}>{data ? data.name : ''}</span></h1>
                {isLoading ? <LeagueLoadingSpinner/> : <LeagueStandings league={data}/>}
            </div>
        </>
    );
}

export default Standings;