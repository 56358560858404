import io from 'socket.io-client';
import Cookies from 'js-cookie';
let socket;
export const initiateSocket = (room) => {
  const STAGE = process.env.NODE_ENV;
  var SOCKET_URL;
  if (STAGE === 'development') {
    SOCKET_URL = "http://localhost/";
  } else {
    SOCKET_URL = "https://api.flowfantasy.com";
  }
  socket = io(SOCKET_URL, {query: `auth_token=${Cookies.get('token')}`});
  console.log(`Connecting socket...`);
  if (socket && room) socket.emit('join', room);
  console.log(`Joining room...`);
}
export const loadInitialChat = (cb) => {
  if (!socket) return(true)
  socket.on('joinResponse', msg => cb(null, msg));
}
export const disconnectSocket = () => {
  console.log('Disconnecting socket...');
  if(socket) socket.disconnect();
}
export const subscribeToChat = (cb) => {
  if (!socket) return(true);
  socket.on('chat', msg => {
    return cb(null, msg);
  });
}
export const sendMessage = (room, message) => {
  if (message.trim() !== '') {
    if (socket) socket.emit('sendChat', { message, room });
  }
}