import { useQuery } from '@apollo/client';
import { PLAYER_STATS_YEARLY_CUMULATIVE } from 'api/pll/Player';
import Loader from 'react-loader-spinner';
import { PointEval } from 'api/pll/Utils';

export const PlayerStats = props => {
    const { loading, error, data } = useQuery(PLAYER_STATS_YEARLY_CUMULATIVE, {
        variables : {
            id: props.id,
            year: parseInt(props.filter)
        }
    });
    return (
        <>
        {loading ? <Loader
            type="Oval"
            color="#99ccff"
            height={25}
            width={25}
            timeout={7000} /> :
            (data.player !== null && data.player.stats ?
                <>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{data.player.stats.goals}</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{data.player.stats.twoPointGoals}</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{data.player.stats.assists}</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{data.player.stats.groundBalls}</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{data.player.stats.turnovers}</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{data.player.stats.causedTurnovers}</td>
                <td style={{textAlign:'center',minWidth:'80px',borderBottom:'2px solid #E5E5E5'}}>{(data.player.stats.savePct*100).toFixed(2)}%</td>
                <td style={{textAlign:'center',minWidth:'90px',borderBottom:'2px solid #E5E5E5'}}>{(data.player.stats.faceoffPct*100).toFixed(2)}%</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{props.team}</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{(PointEval(data.player.stats) / data.player.stats.gamesPlayed).toFixed(0)}</td>
                </>
            : 
                <>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{props.team}</td>
                <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
                </>
            )
        }
        </>
    );
}