import {useState,useEffect} from 'react';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import {useQuery,useMutation} from 'react-query';
import {getWaiver,pickupPlayer} from 'api/LeagueAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import Loader from 'react-loader-spinner';
import 'stylesheets/Waiver.css';
import { ToastContainer, toast } from 'react-toastify';
import { PlayerStats } from 'components/league/PlayerTableStats';
const player_added = () => toast("✅  Player successfully picked up!");
const pickup_error = (error) => toast.error(<div>{error}</div>);

const format_date = (date_str) => {
    var newDate = new Date(date_str);
    var final_date_str = `${newDate.getMonth()+1}/${newDate.getDate()}`;
    return final_date_str;
}


const PlayerNotification = props => {
    return (
        <div>
            {props.data}
        </div>
    );
}

const WaiverPlayer = props => {
    var player = props.data;
    const mutation = useMutation(data => pickupPlayer(props.id,data.player), {
        onSuccess: data => {
            if ('error' in data) {
                pickup_error(`⚠️ ${data['error']}`);
            } else {
                player_added()
                props.setFiltered(props.filtered.filter(pick => pick.officialId !== player.officialId));
            }
        }
    });
    return (
        <tr>
            <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}>{player.position}</td>
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}><span style={{display:'flex',alignItems:'center',fontWeight:'500',fontSize:'19px',marginLeft:'40px',}}><img src={player.profileUrl} className="team-player-img" style={{height:'30px',borderRadius:'10px',marginRight:'10px'}} />{player.firstName} {player.lastName}</span></td>
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{mutation.isLoading ? <Loader type="Oval" color="#99ccff" height={30} width={30} timeout={50000} /> : (props.waiver_period === true ? <button className="waiver-pick-up" onClick={() => mutation.mutate({player:player.officialId})}>Pick Up</button> : null)}</td>
            <td/>
            <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}>{player.next_game.opponent}</td>
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{format_date(player.next_game.start)}</td>
            <PlayerStats filter={props.statsFilter} team={player.teamId} id={player.officialId} />
        </tr>
    );
}

const WaiverTable = props => {
    const [filtered,setFiltered] = useState(props.players);
    useEffect(() => {
        var filtered_players = props.players;
        //Search
        if (props.search.trim() !== '') {
            var filtered_players = filtered_players.filter(player => player.firstName.toLowerCase().includes(props.search.toLowerCase()) || player.lastName.toLowerCase().includes(props.search.toLowerCase()))
        }
        setFiltered(filtered_players);
    },[props.search]);
    
    return (
        <div style={{overflow:'scroll',width:'100%'}}>
        <table className="my-team-table" style={{minWidth:'900px'}}>
            <thead >
                <tr >
                    <th colspan="4" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup' >Players</th>
                    <th colspan="2" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup'>Next Game</th>
                    <th colspan="8" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup'>Stats</th>
                    <th colspan="2" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup'>Basics</th>
                </tr>
                <tr>
                    <th className="team-sub-header" scope='col'>SLOT</th>
                    <th className="team-sub-header" style={{minWidth:'200px'}} scope='col'>PLAYER</th>
                    <th className="team-sub-header" scope='col' />
                    <th className="team-sub-header" scope='col'/>
                    <th className="team-sub-header" scope='col'>OPP</th>
                    <th className="team-sub-header" scope='col'>TIME</th>
                    <th className="team-sub-header" scope='col'>G</th>
                    <th className="team-sub-header" scope='col'>2PT G</th>
                    <th className="team-sub-header" scope='col'>A</th>
                    <th className="team-sub-header" scope='col'>GB</th>
                    <th className="team-sub-header" scope='col'>T</th>
                    <th className="team-sub-header"  scope='col'>CT</th>
                    <th className="team-sub-header" scope='col'>Save %</th>
                    <th className="team-sub-header" scope='col'>Faceoff %</th>
                    <th className="team-sub-header" scope='col'>TEAM</th>
                    <th className="team-sub-header" scope='col'>Pts/Game</th>
                </tr>
            </thead>
            <tbody>
                {filtered.map((player) => (
                    <WaiverPlayer statsFilter={props.statsFilter} id={props.id} setFiltered={setFiltered} filtered={filtered} waiver_period={props.waiver_period} data={player} statsFilter={props.statsFilter} />
                ))}
            </tbody>
        </table>
        </div>
    );
}

const StatsSort = props => {
    return (
        <select className="team-stats-select waiver-stats" onChange={(e) => props.setStatsFilter(e.target.value)}>
            <option value="2021">2021 Stats</option>
            <option value="2020">2020 Stats</option>
            <option value="2019">2019 Stats</option>
        </select>
    );
}

function Waiver({location,match}) {
    const { params: { id } } = match;
    const { isLoading, error, data } = useQuery('waiver',() => getWaiver(id));
    const [statsFilter,setStatsFilter] = useState('2020');
    const [search, setSearch] = useState('');
    return (
        (isLoading ? <LoadingSpinner/> :
            <>
                <Nav league={true} />
                <LeagueNav current="waiver" id={id} />
                <div className="league-main" style={{marginRight:'0px',paddingRight:'0px'}}>
                    <h2 className="my-team-header-collapse" style={{marginBottom:'5px'}}>Waiver</h2>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                        <h1 className="my-team-header">Waiver</h1>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <input className="waiver-player-search" type="text" placeholder="Player Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                            <StatsSort setStatsFilter={setStatsFilter} />
                            
                        </div>
                    </div>
                    <WaiverTable players={data.players}id={id} search={search} waiver_period={data.waiver_period} statsFilter={statsFilter} />
                </div>
                <ToastContainer/>
            </>
        )
    );
}

export default Waiver;