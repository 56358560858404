import { useState,useEffect } from 'react';
import { useQuery } from 'react-query';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import LeagueLoadingSpinner from 'components/LeagueLoadingSpinner';
import { scoreboard } from 'api/LeagueAPI';
import { Row, Col } from 'react-simple-flex-grid';
import 'stylesheets/Scoreboard.css';

const weeks_info = {0:'Matchup 1 (Jun 4 - 6)',1:'Matchup 2 (Jun 11 - 13)', 2:'Matchup 3 (Jun 25 - 27)',3:'Matchup 4 (Jul 2 - 4)',4:'Matchup 5 (Jul 9 - 11)',5:'Matchup 6 (Jul 30 - Aug 1)',6:'Matchup 7 (Aug 13 - 15)'}

const MatchupSelector = props => {
    return (
        <select onChange={(e) => props.setWeek(e.target.value)} style={{backgroundColor:'#fbfbfb',borderRadius:'40px',border:'1px solid #cbccce',padding:'10px',outline:'none',marginLeft:'10px'}}>
            <option value="current">Current Matchup</option>
            {Object.keys(weeks_info).map((key,idx) => (
                <option key={idx} value={key}>{weeks_info[key]}</option>
            ))}
        </select>
    );
}

const MatchupCard = props => {
    return (
        <div style={{backgroundColor:'#fff',border:"1px solid rgb(220, 221, 223)",borderRadius:'10px',margin:'10px',display:'flex',justifyContent:'space-between'}}>
            <div style={{paddingLeft:'20px',paddingTop:'10px',paddingBottom:'10px',width:'100%'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%',height:'50px'}}>
                    {props.matchup[0] ?
                    <>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <img src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.matchup[0].logo}`} style={{height:'30px',borderRadius:'15px',marginRight:'10px'}} />
                            <p style={{fontSize:'18px',fontWeight:'500',margin:'0px'}}>{props.matchup[0].name}</p>
                        </div>
                        <div style={{paddingRight:'20px',fontWeight:'600',fontSize:'20px'}}>
                            {props.matchup[0].score}
                        </div>
                    </>
                    : <div style={{marginLeft:'40px',fontSize:'18px',fontWeight:'500'}}>Bye</div>}
                    
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%',height:'50px'}}>
                    {props.matchup[1] ?
                    <>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <img src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.matchup[1].logo}`} style={{height:'30px',borderRadius:'15px',marginRight:'10px'}} />
                            <p style={{fontSize:'18px',fontWeight:'500',margin:'0px'}}>{props.matchup[1].name}</p>
                        </div>
                        <div style={{paddingRight:'20px',fontWeight:'600',fontSize:'20px'}}>
                            {props.matchup[1].score}
                        </div>
                    </>
                    : <div style={{marginLeft:'40px',fontSize:'18px',fontWeight:'500'}}>Bye</div>}
                </div>
            </div>
            <div className="box-score-btn-wrapper">
                <button className="box-score-btn" onClick={() => window.location.href=`/league/${props.id}/boxscore?week=${props.week}&matchupId=${props.matchupId}`}>Box Score</button>
            </div>
        </div>
    );
}

const WeekScoreboard = props => {
    return (
        <Row>
            {props.matchups.map((matchup,idx) => (
                <Col xs={{span:12}} sm={{span:12}} md={{span:6}}><MatchupCard id={props.id} matchup={matchup} week={props.week} matchupId={idx}/></Col>
            ))}
        </Row>
    );
}

function Scoreboard({match,location}) {
    const { params: { id } } = match;
    const [week,setWeek] = useState('current');
    const { isLoading, error, data } = useQuery(['scoreboard',week],() => scoreboard(id,week));
    return (
        <>
            <Nav league={true}/>
            <LeagueNav id={id}/>
            <div className="league-main">
                <h1>Scoreboard <span style={{fontSize:'20px',fontWeight:'500',color:'#4A4A4A',whiteSpace:'nowrap'}}>{data ? data.name : ''}</span></h1>
                <div style={{width:'100%',backgroundColor:'#F3F3F3',borderRadius:'15px'}}>
                    <div style={{fontWeight:'500',marginLeft:'20px',paddingTop:'10px'}}>Matchups <MatchupSelector setWeek={setWeek} /></div>
                    {isLoading ? <LeagueLoadingSpinner/> :
                        <WeekScoreboard id={data._id} week={week} matchups={data.matchups}/>
                    }
                </div>
                
            </div>
        </>
    );
}

export default Scoreboard;